<template>
  <div class="full-width full-height">
    <back v-show="false" />
    <tab :tabs="tabs" :initTab="initTab" @select="selectHandle" />
    <div class="m-t flex justify-between m-b-sm">
      <div class="flex flex-one items-center text-lightblue">
        <span class="flex-s f14 m-r-xs">角色名称：</span>
        <a-input
          v-model="searchTotal.role"
          placeholder="角色名称"
          class="m-r-sm search-mx-wd-1"
          >角色名称</a-input
        >
        <span class="flex-s f14 m-r-xs">状态：</span>
        <a-select
          v-model="searchTotal.status"
          placeholder="请选择状态"
          class="search-mx-wd-1 m-r-sm"
        >
          <a-select-option :value="1">启用</a-select-option>
          <a-select-option :value="0">停用</a-select-option>
        </a-select>

        <span class="flex-s f14 m-r-xs">创建时间：</span>
        <a-range-picker
          class="m-r-sm search-date-picker"
          v-model="searchTotal.time"
          @change="onChangeDate"
        />

        <a-button
          type="primary"
          class="m-r-sm btn-search"
          @click="searchParmas"
        >
          查询
        </a-button>
        <a-button @click="resetClear" class="btn-reset m-r-sm">
          重置
        </a-button>
      </div>
      <div class="flex">
        <span class="span-line m-l-md m-r-md"></span>
        <a-button
          type="button"
          @click="onAddChanges"
          class="m-r-sm btn-export"
        >
          新增
        </a-button>

        <a-dropdown>
          <a-button class="btn-export">
            批量处理<a-icon type="caret-down" class="m-l-sm" />
          </a-button>
          <a-menu slot="overlay" @click="setRole">
            <a-menu-item key="1" v-if="hasPermission(1092171114)">
              <span class="block text-center">启用</span>
            </a-menu-item>
            <a-menu-item key="2" v-if="hasPermission(1092171115)">
              <span class="block text-center">停用</span>
            </a-menu-item>
            <a-menu-item key="3" v-if="hasPermission(1092171113)">
              <span class="block text-center">删除</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-table
      bordered
      class="m-t"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: getCheckboxProps,
      }"
      :components="resibleTableHeader"
      :columns="columns"
      :data-source="dataRole"
      :rowKey="(record, index) => index"
      :pagination="paginationOpt"
      :scroll="scroll"
      :loading="loading"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <template slot="statu" slot-scope="text">
        {{ text === 1 ? '启用' : '停用' }}
      </template>
      <span
        slot="action"
        slot-scope="text, record"
        v-if="record.roleCode != 100000001"
      >
        <a-icon class="m-r" type="form" @click="editRole(record)" />
        <a-icon @click="deleteRole(record)" class="m-r" type="rest" />
        <!-- <a-icon type="solution" /> -->
      </span>
    </a-table>

    <role-edit
      :visible="addVisible"
      :currentRole="currentRole"
      :isEdit="isEdit"
      @close="closeHandle"
      @sure="sureHandle"
    />
    <!-- 点击删除弹出框 -->
    <!-- <role-edit-delete :visible="addVisibles" @close="closeHandles" /> -->
  </div>
</template>
<script>
import Back from '@/components/Back';
import RoleEdit from '@/components/RoleEdit.vue';
// import RoleEditDelete from '@/components/RoleEditDelete.vue';
import moment from 'moment';
import Tab from '@/components/Tab';
import tabMixin from '@/components/mixins/tabs';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'RoleManage',
  mixins: [tabMixin,ResizableTable],
  data() {
    return {
      loading: false,
      scroll: { y: window.innerHeight - 350,x:'100%' },
      columns: [
        {
          title: '序号',
          dataIndex:'roleIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
          width:100,
          ellipsis:true
        },
        {
          title: '角色描述',
          dataIndex: 'roleDesc',
          key: 'roleDesc',
          width:220,
          ellipsis: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createDate',
          key: 'createDate',
          width:120,
          ellipsis:true,
        },
        {
          title: '创建人',
          dataIndex: 'createUser',
          key: 'createUser',
          width:90,
          ellipsis:true,
        },
        {
          title: '关联用户',
          dataIndex: 'count',
          key: 'count',
          width:60,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'statu' },
          className:'column-center',
          width:60,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 80,
          className:'column-center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataRole: [],
      searchTotal: {
        role: undefined,
        time: null,
        status: undefined,
      },
      addVisible: false,
      addVisibles: false,
      // 分页
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: total => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this.resetRow();
          this._getRoleList();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this.resetRow();
          this._getRoleList();
        },
      },
      selectedRowKeys: [],
      selectedRowAll: [],
      status: 0,
      isEdit: false,
      currentRole: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this._getRoleList();
  },
  methods: {
    selectHandle(index) {
      this.selectCurrent(index);
    },
    onSelectChange(selectedRowKeys, selectedRowAll) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowAll = selectedRowAll;
    },

    // 选择框的默认属性配置
    getCheckboxProps(row) {
      return {
        props: {
          disabled: row.roleCode == 100000001,
        },
      };
    },

    resetRow() {
      this.selectedRowKeys = [];
      this.selectedRowAll = [];
    },
    _getRoleList() {
      let params = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        roleName: this.searchTotal.role,
        startDate: this.searchTotal.time
          ? moment(this.searchTotal.time[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        endDate: this.searchTotal.time
          ? moment(this.searchTotal.time[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        status: this.searchTotal.status,
      };
      this.loading = true;
      this.$api.aiCenterS.getRoleList(params).then(res => {
        // console.log(res);
        if (res.code === 200) {
          this.dataRole = res.data;
          this.paginationOpt.total = res.total;
          this.loading = false;
          this.resetRow();
        }
      });
    },

    closeHandle() {
      this.addVisible = false;
      this.isEdit = false;
    },
    sureHandle() {
      this.closeHandle();
      this.paginationOpt.current = 1;
      this._getRoleList();
    },
    closeHandles() {
      this.addVisibles = false;
    },
    onAddChanges() {
      if (!this.hasPermission(1092171110)) {
        this.permissionWarning();
        return;
      }

      this.addVisible = true;
      this.isEdit = false;
      this.currentRole = {};
    },
    deleteRole(record) {
      if (!this.hasPermission(1092171112)) {
        this.permissionWarning();
        return;
      }

      let data = {
        roleCodes: [record.roleCode],
      };
      this.$confirm({
        title: '提示',
        content: '你确定删除该角色吗?',
        onOk: () => {
          this.delRoleInfo(data);
        },
        onCancel: () => {},
      });
    },
    onChangeDate(time) {
      this.searchTotal.time = time.length > 0 ? time : null;
    },
    resetClear() {
      this.paginationOpt.current = 1;
      this.searchTotal.role = undefined;
      this.searchTotal.time = null;
      this.searchTotal.status = undefined;
      this._getRoleList();
    },
    searchParmas() {
      this._getRoleList();
    },
    setRole({ key }) {
      switch (key) {
        case '1':
          this.status = 1;
          this.setStatus();
          break;
        case '2':
          this.status = 0;
          this.setStatus();
          break;
        case '3':
          this.batchDelRole();
          break;
      }
    },
    batchDelRole() {
      let data = {
        roleCodes: this.selectedRowAll.map(item => item.roleCode),
      };
      if (!data.roleCodes.length) {
        this.$message.error('请选择角色');
        return;
      }
      this.$confirm({
        title: '提示',
        content: '你确定删除该角色吗?',
        onOk: () => {
          this.delRoleInfo(data);
        },
        onCancel: () => {},
      });
    },
    delRoleInfo(data) {
      this.$api.aiCenterY.batchRoleData(data).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this._getRoleList();
        }
      });
    },
    setStatus() {
      let statusText = this.status === 1 ? '启用成功' : '停用成功';
      let data = {
        roleCodes: this.selectedRowAll.map(item => item.roleCode),
        status: this.status,
      };
      if (!data.roleCodes.length) {
        this.$message.error('请选择角色');
        return;
      }
      this.$api.aiCenterY.batchRoleStatus(data).then(res => {
        if (res.code === 200) {
          this.$message.success(statusText);
          this._getRoleList();
        }
      });
    },
    editRole(record) {
      if (!this.hasPermission(1092171111)) {
        this.permissionWarning();
        return;
      }

      this.$api.aiCenterS
        .getChoseListByAllRole({ roleCode: record.roleCode })
        .then(res => {
          if (res.code === 200) {
            record.functionInfo = res.data;
            this.currentRole = record;
            this.addVisible = true;
            this.isEdit = true;
          }
        });
    },
  },
  components: {
    Back,
    RoleEdit,
    Tab,
    // RoleEditDelete,
  },
};
</script>

<style scoped>
.wd40 {
  width: 120px;
}
</style>
