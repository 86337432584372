<template>
  <a-modal
    :title="getTitle"
    width="30%"
    :visible="visible"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <vue-scroll :ops="$root.scrollOpsY" :style="getHt()">
      <div class="flex m-b items-center">
        <span class="text-white m-r-md wd40 text-right"
          ><span class="text-red">*</span>角色名称:</span
        >
        <a-input v-model="roleName" placeholder="角色名称" class="flex-one" />
      </div>
      <div class="flex m-b">
        <span class="text-white m-r-md wd40 text-right"
          ><span class="text-red">*</span>角色描述:</span
        >
        <div class="flex-one">
          <a-textarea v-model="des" placeholder="角色描述" class="flex-one" />
          <span class="text-red block text-right"
            >{{ des ? des.length : 0 }}/200</span
          >
        </div>
      </div>
      <div class="flex m-b items-center">
        <span class="text-white m-r-md wd40 text-right">状态:</span>
        <a-radio-group v-model="status" @change="onChange">
          <a-radio :value="1">
            启用
          </a-radio>
          <a-radio :value="0">
            停用
          </a-radio>
        </a-radio-group>
      </div>
      <div class="flex m-b">
        <span class="text-white m-r-md wd40 text-right">关联权限:</span>
        <div class="flex-one">
          <a-tree
            v-model="checkedKeys"
            checkable
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :selected-keys="selectedKeys"
            :tree-data="treeData"
            @expand="onExpand"
            @check="onCheck"
          />
        </div>
      </div>
    </vue-scroll>
  </a-modal>
</template>

<script>
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'RoleEdit',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    currentRole: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      roleName: '',
      status: 1,
      des: '',
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      halfFunctionCodes: [],
      selectedKeys: [],
      treeData: [],
    };
  },
  computed: {
    getTitle() {
      return `${this.isEdit ? '编辑' : '新增'}`;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    visible(nv, ov) {
      if (nv) {
        this._getAuthority();
      }
    },
    isEdit(nv, ov) {
      if (nv && nv !== ov) {
        this.initDefault();
      }
    },
  },
  mounted() {
    this._getAuthority();
  },
  methods: {
    getHt() {
      let maxHt = document.documentElement.clientHeight;
      let ht = maxHt - 260;
      return { height: `${ht}px` };
    },
    initDefault() {
      this.roleName = this.currentRole.roleName;
      this.status = this.currentRole.status;
      this.des = this.currentRole.roleDesc;
      this.checkedKeys = this.currentRole.functionInfo[
        this.currentRole.roleCode
      ].filter((item) => !item.includes('1093'));
      this.halfFunctionCodes = this.currentRole.functionInfo['half'];
    },
    _getAuthority() {
      let obj = {
        enable: '',
        functionType: '',
        functionName: '',
        sceneType: 1,
      };
      this.$api.authority.getAuthority(obj).then((res) => {
        if (res.code === CODE_OK) {
          this.treeData = this.trackAuthority(res.data);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    trackAuthority(authority, key = '1') {
      let arr = [];
      if (authority.length > 0) {
        // eslint-disable-next-line no-unused-vars
        arr = authority.map((item, index) => {
          // item.key = `${key}-${index}`;
          item.key = item.functionCode;
          item.title = item.functionDesc;
          if (item.childNode && item.childNode.length > 0) {
            item.children = this.trackAuthority(item.childNode, item.key);
          }
          return item;
        });
      }

      return arr;
    },
    resetData() {
      this.roleName = '';
      this.status = 1;
      this.des = '';
      this.checkedKeys = [];
      this.expandedKeys = [];
    },
    handleCancel() {
      this.resetData();
      this.$emit('close');
    },
    handleOk() {
      let obj = {
        functionCodes: this.checkedKeys,
        halfFunctionCodes: this.halfFunctionCodes,
        roleDesc: this.des,
        roleName: this.roleName,
        status: this.status,
      };
      if (!obj.roleName) {
        this.$message.error('角色名称不能为空');
        return;
      }
      if (!obj.roleDesc) {
        this.$message.error('角色描述不能为空');
        return;
      }
      if (!this.isEdit) {
        this.$api.role.addRole(obj).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('新增角色成功！');
            this.resetData();
            this.$emit('sure');
          }
        });
      } else {
        obj.roleCode = this.currentRole.roleCode;

        // jurisdictionFlag: 部1省3
        obj.jurisdictionFlag = this.$g.isDepart ? 1 : 3;
        this.$api.role.modifyRole(obj).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('修改角色成功！');
            this.resetData();
            this.$emit('sure');
          }
        });
      }
    },
    onChange(e) {
      this.status = e.target.value;
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys, e) {
      // console.log('onCheck', checkedKeys, e);
      this.halfFunctionCodes = e.halfCheckedKeys;
      this.checkedKeys = checkedKeys;
    },
  },
};
</script>

<style scoped>
.wd40 {
  width: 80px;
}
</style>
